import React from "react";
import {ConstructSection} from "../section";

const ConstructPage = () => {
    return (
        <ConstructSection/>
    );
};

export default ConstructPage;
