import {
  ConstructPage
} from "../pages/index";
import Layout from "../layout/mainLayout";
import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <ConstructPage />
      </Layout>
    ),
    errorElement: <ConstructPage />,
  }
]);

export default router;
