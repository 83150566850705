import React from "react";
import "./construct.scss";
import { Logo } from "../../asset/image";

const constructPage = () => {
    return (
        <div className="construction">
            <img src={Logo} alt="Logo"/>
            <h1>En construction</h1>
        </div>
    );
}

export default constructPage;