import * as React from "react";
import { RouterProvider } from "react-router-dom";
import router from "./routes/routes";
function App() {

  return (
    <RouterProvider router={router}>{/* <GoogleAnalytics /> */}</RouterProvider>
  );
}

export default App;
